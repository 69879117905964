import React, { Component } from "react";
import PropTypes from "prop-types";

export class Hero extends Component {
  state = {
    page: null,
    media: null,
  };

  heroMedia = (thisPage) => {
    if (thisPage?.acf.iframe) {
      return (
        <iframe
          src={thisPage?.acf.iframe}
          title={thisPage?.title.rendered}
        ></iframe>
      );
    } else if (thisPage.acf.gallery && thisPage.acf.gallery.length > 0) {
      const img = (
        <img
          src={thisPage.acf.gallery[0].full_image_url}
          alt={thisPage.acf.gallery[0].alt_text}
          width="100%"
        />
      );
      if (thisPage.acf.gallery[0].caption != "")
        return (
          <a href={thisPage.acf.gallery[0].caption} target="_blank">
            {img}
          </a>
        );
      return <div>{img}</div>;
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.state.page == null &&
      this.props.pages.length > 0 &&
      prevProps.pages.length === 0
    ) {
      var thisPage = this.props.pages.filter(
        (page) => page.slug === this.props.slug
      )[0];
      var thisMedia = this.heroMedia(thisPage);
      this.setState({
        page: thisPage,
        media: thisMedia,
      });
    }
  }

  render() {
    const { page, media } = this.state;

    return (
      <div className="hero bg-base-200">
        <div className="hero-content flex-col lg:flex-row my-16 mx-8">
          {media}
          <div>
            <h1 className="text-5xl font-bold">{page?.title.rendered}</h1>
            <p
              className="py-6"
              dangerouslySetInnerHTML={{ __html: page?.content.rendered }}
            />
            {/* <button className="btn btn-primary">Get Started</button> */}
          </div>
        </div>
      </div>
    );
  }
}

Hero.propTypes = {
  pages: PropTypes.array.isRequired,
  slug: PropTypes.string.isRequired,
};

export default Hero;
